
.spacer{
margin-bottom:2.5rem;
}


.alert-component{
height:150vh !important;
margin:0 !important;
min-height: 100vh;
max-height: 150vh;

}

.alert-content{
  padding:3rem;
}

.logo-figure {
  margin-bottom: 2rem;
  }

.main-figure{
  margin-bottom:2rem;
  }
 
  

.accordion {
  position: relative;
  top: 30px;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 80px;
  display:flex;
  flex-direction: column;
  /* align-items: center; */
}

.search-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 10rem;
}

.contrib-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 10rem;
}

.reference-box{
  width:60%;
  text-align: justify;
  margin:25px auto;  
}

@media all and (min-width: 768px){

/* .home-main{
  height: 120vh;  
} */

.alert-component{
  height:150vh !important;
  min-height: 100vh;
  max-height: 150vh;
  text-align: center;
}
.alert-oneliner {
  font-size: 1.2rem;
}


.accordion {
  margin-top:3rem;
  width:40%;
  /* margin-bottom: 40%; */
  /* position: relative;
  top: 30px;
  margin: 0 auto;
  width:60%;
  margin-bottom: 80px;
  display:flex;
  flex-direction: column nowrap; */
  /* justify-content: center; */
  /* align-items: center; */
}

.accordion-header {
  font-size: 1.3rem;
}

.logo-figure {
margin-right: 8rem;
margin-top: 3rem;  
}

.main-figure{
  margin-left: 2rem;
  margin-top:3rem;
}

.figure-image {
  max-width: 100%;
  height:auto;
}


}