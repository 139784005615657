

.custom-navbar {
position:fixed !important;
top:8rem !important;
left:4.8rem !important;
max-height: 100vh !important;
margin: 0 !important;
padding: 0 !important;
max-height: 150vh !important;
}

.nav-item {
  margin-bottom:7rem;
}