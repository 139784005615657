/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100&display=swap'); */

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "Noto Sans", "DM-Sans", Verdana, "Times New Roman";
  box-sizing: border-box;
  



/*   height: 100vh; */
/*   border: 1px solid black; */

}

#root{
  --bs-gutter-x: 0 !important;
}

/* :root {

}  */



