

.main-search-result {
  min-height:100vh !important;
}

.search-alert-component {
  height: 150vh !important;
  max-height: 150vh !important;
}

.alert-heading,
.alert-summary,
.alert-heading {
  text-align: center;
  padding-top: 20px;
}

.select-label {
  position: relative;
  left: 48px;
}

.button-container {
  margin: 0 auto;
  padding-top: 50px;
}

.year-fields-container {
  position: relative;
  left: 250px;
}



.search-result-alert-component{
 min-height:100vh;
}



.no-results-message{
  height:100vh;
}