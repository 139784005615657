.about-alert{
    height:100vh;
}

.alert-heading-about{
    position:relative;
    top:12rem;
}
.alert-summary-about{
    text-align: center;
    font-size: 1.3rem;
    position:relative;
    top:10rem;
    padding-top:1rem;
}

.temporary-about-wrapper{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position:relative;
    top:10rem;
    gap:5rem;
}

.archipelagos-italia{

}
.archipelagos-link{
    display:block;   
    font-size: 1.5rem;
    
}